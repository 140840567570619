import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react'

import { 
  Button,
  Card,
  Layout,
  ButtonGroup,
  Tooltip,
  Icon,
} from "@shopify/polaris"
import { CalendarMajor } from '@shopify/polaris-icons';
import { Provider, ResourcePicker } from '@shopify/app-bridge-react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import { format } from 'date-fns'
import OfferList from './list';
import FirebaseContext from '../../../providers/firebase';
import toOfferTypeCreate from './utils/navigateHandlers';
import MigrateBannerForList from './components/migrateBannerForList/migrateBannerForList';
import MigrationWizardModal from './components/migrationWizardModal/migrationWizardModal';
import calAnalyticsPerOffer from '../../../helpers/calAnalyticsPerOffer';
import FeedbackBanner from '../feedbackBanner';
import { navigate } from 'gatsby';
import './page.css';

const styles = {
  upsellContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    borderRadius: 5,
    border: '1px #d9d9d9 solid',
    padding: 10,
  },
  upsellActionContainer: {
    display: "flex",
    minWidth: '100%',
    textAlign: "right",
    flex: 2,
    marginBottom: 20,
  },
  imageContainer: {
    display: "inline-block",
    padding: 5,
    minWidth: 100,
    textAlign: "center",
  },
  metadataContainer: {
    display: "flex",
    padding: 5,
    minWidth: 100,
    textAlign: "left",
    margin: 'auto',
    flex: 2,
    flexDirection: 'column',
  },
  actionContainer: {
    display: "flex",
    padding: 5,
    minWidth: 100,
    textAlign: "right",
    margin: 'auto',
    flex: 1,
    flexDirection: 'column',
  },
}

function Offers(props) {
  const { rawAnalytics, chartDateRange } = props
  const [migrateBannerActive, setMigrateBannerActive] = useState(false);
  const [renderModal, setRenderModal] = useState(false);
  const [hasCheckoutExtension, setHasCheckoutExtension] = useState(false);

  const { firebase, shop, token, host } = useContext(FirebaseContext);
  const [analytics, setAnalytics] = useState(calAnalyticsPerOffer(rawAnalytics));
  const shopDoc = doc(firebase.firestore, 'shops', shop);
  const rulesDoc = doc(firebase.firestore, 'rules', shop);
  const [rules, rulesLoading, rulesError] = useDocumentData(
    rulesDoc,
    {
      transform: (snap) => snap.rules
    }
  );

  const [shopData, shopDataLoading, shopDataError] = useDocumentData(
    shopDoc,
    {
      transform: (snap) => snap.shopData
    }
  );
  const toggleAllIsEnable = rules ? rules.every(rule => !rule.enabled) : false;
  const domain = shopData && shopData.domain
  const locale = shopData && shopData.primary_locale || "en-US";
  const currency = shopData && shopData.currency || "USD";

  useEffect(() => {
    if (rules && rules.some((item) => item?.checkoutType === "extension" && item?.offerType === "Checkout")) {
      setHasCheckoutExtension(true);
    }
  }, [rules, setHasCheckoutExtension]);

  const handleGoToCheckout = useCallback(() => {
    if (hasCheckoutExtension) {
      window.open(`https://${domain}/admin/settings/checkout/editor`, '_blank')
    } else {
      window.open(`https://${domain}/admin/settings/checkout`, '_blank')
    }
  }, [hasCheckoutExtension, domain]);

  return (
    <Fragment>
      {(Array.isArray(rules) || shopDataLoading) ?
      <Layout>
          <Layout.Section>
            <div className='OffersPageHeader'>
              
              
              <ButtonGroup>
              {chartDateRange && chartDateRange.startDate && chartDateRange.endDate && 
              <Tooltip content="This date range is adopted from your analytics tab." dismissOnMouseOut>
              <Button>
                <div style={{display:'flex', alignItems: 'center',justifyContent: 'center', gap: '5px'}}>
                <Icon
                  source={CalendarMajor}
                  color="base" style={{display:'inline-block'}}></Icon>
                    <span>{`${format(chartDateRange.startDate, 'M/d')} to ${format(chartDateRange.endDate, 'M/d')}`}</span>
                </div>
              </Button>
              </Tooltip>
              }
                <Button onClick={handleGoToCheckout}>
                  {!hasCheckoutExtension ? 'Go to checkout' : 'To Checkout Editor'}
                </Button>
                <Button primary onClick={() => toOfferTypeCreate({state: rules})}>
                  New offer
                </Button>
              </ButtonGroup>
            </div>
          </Layout.Section>

          <Layout.Section>
            <Card>
              <OfferList
                rules={rules} 
                locale={locale}
                currency={currency}
                analytics={analytics}
                chartDateRange={chartDateRange}
                {...props}
              />
            </Card>
          </Layout.Section>
      </Layout>
      :
      <Layout>
        <Layout.Section>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80vh'}}>
            <div style={{textAlign: 'center'}}>
              <div style={{marginBottom: '30px'}}>
                <img src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/empty-upsell-card.png?alt=media&token=46b865f8-2c59-4020-aed8-bffcae2c6ab1" width="375"/>
              </div>
              <Button primary onClick={() => {
                navigate(
                  `/app/offers/createOfferType`,
                  {
                      replace: true,
                  }
              )
              }}>Create an offer</Button>
            </div>
          </div>
        </Layout.Section>
      </Layout>
        }
      <Provider
        config={{apiKey: process.env.GATSBY_SHOPIFY_APP_API_KEY, host: host }}
      >
        <ResourcePicker 
          allowMultiple={false}
          resourceType="Product" 
          open={renderModal}
          onCancel={() => setRenderModal(false)}
          onSelection={(selectPayload) => {
            setRenderModal(false)
            props.onSelectProduct(selectPayload)
          }}
          showVariants={true}
        />
      </Provider>
      {
        Array.isArray(rules) &&
          <MigrationWizardModal
            active={migrateBannerActive}
            setActive={setMigrateBannerActive}
            rules={
              rules.filter((rule) => rule.offerType === "Checkout"
                && (!rule?.checkoutType || rule?.checkoutType !== "extension" ))
            }
            shop={shop}
            firebase={firebase}
          />
      }
    </Fragment>
  );
}


export default Offers;