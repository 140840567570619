import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
} from 'react'

import { 
  Page,
  Tabs,
  Frame,
  Loading,
  Layout,
  Card,
  Icon,
  Select,
  Stack,
} from "@shopify/polaris"
import { LanguageMinor } from '@shopify/polaris-icons';
import DashboardPage from './dashboard'
import OffersPage from './offers/page'
import SettingsPage from './settings'
import HelpdeskPage from './helpdesk'
import List from './customizations/list'
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { withFirebase } from '../../providers/firebase'
import getTodayAnalytics from '../../helpers/getTodayAnalytics'
import getAnalytics from '../../helpers/getAnalytics'
import getRules from '../../helpers/getRules'
import createRule from '../../helpers/createRule'
import Constants from '../../helpers/constants'
import selectPayloadToData from '../../helpers/selectPayloadToData'
import "./global.css"


function Index(props) {

  const { firebase, token, shop, host, user_id, initialSelectedTabIndex = Constants.TAB_INDEX.ANALYTICS, location = {} } = props
  const locationState = location.state || {}
  const [selectedTabIndex, setSelectedTabIndex] = useState(locationState.tabIndex || initialSelectedTabIndex)

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelectedTabIndex(selectedTabIndex),
    [],
  )
  const [rules, setRules] = useState(locationState.rules || [])
  const [analytics, setAnalytics] = useState(locationState.analytics || null)
  const [refreshLoading, setRefreshLoading] = useState(false)
  const [refreshCount, setRefreshCount] = useState(0)
  const [analyticsToday, setAnalyticsToday] = useState(null)
  const [getAllAnalytics, setGetAllAnalytics] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [selected, setSelected] = useState('en');
  const today = new Date(new Date().setHours(0,0,0,0));
  const [chartDateRange , setChartDateRange] = useState(locationState.chartDateRange || {startDate: new Date(new Date().setDate(today.getDate() - 30)), endDate: today})
  const handleSelectChange = (value) => { 
    console.log('value', value)
    setSelected(value) 
    window.translatePage(value)
  }
  
  useEffect(() => {
    // useEffect cannot directly be async
    const run = async () => {
      const rulesResult = await getRules(token, shop, host)
      setRules(rulesResult.data.rules)
      setLoaded(true)
    }
    run()
  }, [token, shop, selectedTabIndex]);

  useEffect(() => {
    const run = async () => {
      const analyticsResult = await getAnalytics(token, shop, host, chartDateRange.startDate, chartDateRange.endDate)
      setAnalytics(analyticsResult.data.data)
      
      setRefreshCount(refreshCount+1)
      //calAnalyticsPerOffer(analyticsResult.data.data)
    }
    run()
  }, [token, shop, chartDateRange]);

  useEffect(() => {
    const run = async () => {
      try{
        const analyticsTodayResult = await getTodayAnalytics(token, shop, host)
        setAnalyticsToday(analyticsTodayResult.data.result)
      }catch(e){
        console.log(e)
      }
    }
    if(refreshCount && (!analyticsToday || chartDateRange.endDate.getTime() >= today.getTime() || ( Array.isArray(analyticsToday) && analyticsToday.length === 1 && analyticsToday[0].dateString && chartDateRange.endDate.getTime() >= new Date(analyticsToday[0].dateString).setHours(0,0,0,0)))){
      run()
    }
  }, [refreshCount]);

  useEffect(() => {
    if(Array.isArray(analyticsToday) && analyticsToday.length === 1 && analytics && (chartDateRange.endDate.getTime() >= today.getTime() || (analyticsToday[0].dateString && chartDateRange.endDate.getTime() >= new Date(analyticsToday[0].dateString).setHours(0,0,0,0)))){
      let allAnalytics
      const index = analytics.findIndex(item => item.dateString === analyticsToday[0].dateString)
      if (index !== -1) {
        allAnalytics = [...analytics]
        allAnalytics[index] = analyticsToday[0];
      } else {
        allAnalytics = analytics.concat(analyticsToday[0])
      }
      let totalImpressions = 1
      if(refreshCount == 1){
        totalImpressions = allAnalytics.reduce((memo, item) => {
          return memo + Number(item?.impressions?.totalCount)
        }, 0)
      }
      setAnalytics(allAnalytics)
      if(totalImpressions === 0 && !getAllAnalytics && refreshCount == 1){
        setGetAllAnalytics(true)
      }
    }
  }, [analyticsToday, refreshCount])
  
  useEffect(() => {
    const run = async () => {
      try{
        const analyticsResult = await getAnalytics(token, shop, host, chartDateRange.startDate, chartDateRange.endDate, getAllAnalytics)
        setAnalytics(analyticsResult.data.data)
        setRefreshCount(refreshCount+1)
      }catch(e){
        console.log(e)
      }
    }
    if(getAllAnalytics){
      run()
    }
  }, [getAllAnalytics])

  const refresh = async() => {
    if(refreshLoading){
      return
    }
    setRefreshLoading(true)
    const analyticsResult = await getAnalytics(token, shop, host, chartDateRange.startDate, chartDateRange.endDate, getAllAnalytics)
    setAnalytics(analyticsResult.data.data)
    setRefreshCount(refreshCount+1)
    setRefreshLoading(false)
  }
  const tabs = [
    {
      id: 'dashboard-1',
      content: 'Analytics',
      accessibilityLabel: 'Analytics',
      panelID: 'dashboard-content-1',
    },
    {
      id: 'upsell-offers-1',
      content: 'Offers',
      panelID: 'upsell-offers-content-1',
    },
    {
      id: 'customization-1',
      content: 'Customizations',
      panelID: 'customization-content-1',
    },
    {
      id: 'settings-1',
      content: 'Settings',
      panelID: 'settings-content-1'
    },
    {
      id: 'helpdesk-1',
      content: 'Helpdesk',
      panelID: 'helpdesk-content-1'
    },
    
  ] 

  const languageOptions = [
    {label: 'English', value: 'en'},
    {label: 'Spanish', value: 'es'},
    {label: 'Portuguese', value: 'pt'},
    {label: 'French', value: 'fr'},
    {label: 'German', value: 'de'},
  ];
  if (!loaded) {
    return (
      <Frame>
        <Loading />
        <Page fullWidth title="Loading...">

          <Layout>
            <Layout.Section>
              <Card>
                <Card.Section>
                  <p>{`Loading shop ${shop}...`}</p>
                </Card.Section>
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
      </Frame>
    );
  }
  else {
    return (
      <Fragment>
        <div style={{position:'absolute', right: '3.2rem', top: '1rem', display:'flex', flexDirection: 'row', alignItems: 'center', gap: '3rem'}}>
          {/*
          <div>
            <Stack distribution="center" alignment='center' spacing='tight'>
              <Icon
                source={LanguageMinor}
                color="base"
              />
              <Select
                label="Language"
                labelHidden={true}
                options={languageOptions}
                onChange={handleSelectChange}
                value={selected}
              />
            </Stack>
          </div>
          */}
          <div style={{fontWeight:'500', color:'#6D7175'}}>
            Version 4.0 | <a href="javascript:void(0)" onClick={
                                      () => {
                                          window.open(`https://upsellplus.notion.site/UpsellPlus-Changelog-04f961620895422dafd3b69eb6ed30b9`, '_blank')
                                      }
                                  } style={{color:'#6D7175'}}>What's new</a>
          </div>
        </div>
        <Tabs tabs={tabs} selected={selectedTabIndex} onSelect={handleTabChange}>
          <Page
            fullWidth
          >
            <div style={{
              paddingTop: 20,
            }}>
              {
                selectedTabIndex === 0 && 
                  <DashboardPage 
                    rawAnalytics={analytics}
                    rules={rules}
                    key={analytics && analytics.length > 0 ? analytics[0].dateString+analytics[analytics.length-1].dateString + refreshCount : refreshCount}
                    setChartDateRange={setChartDateRange}
                    chartDateRange={chartDateRange}
                    refreshLoading={refreshLoading}
                    refresh={refresh}
                    setSelectedTabIndex={setSelectedTabIndex}
                    {...props}
                  />
              }
              {
                selectedTabIndex === 1 && analytics &&
                  <OffersPage 
                    {...props} 
                    rules={rules}
                    rawAnalytics={analytics}
                    chartDateRange={chartDateRange}
                    key={analytics && analytics.length > 0 ? analytics[0].dateString+analytics[analytics.length-1].dateString + refreshCount: refreshCount}
                    onSelectProduct={async (selectPayload) => {
                      const data = selectPayloadToData(selectPayload)
                      if (!data) {
                        return
                      }

                      const result = await createRule(data, token, shop, host)
                      const rules = result.data.rules
                      setRules(rules) 
                    }}
                  />
              }
              {
                selectedTabIndex === 2 &&
                <List {...props} />
              }
              {
                selectedTabIndex === 3 &&
                <SettingsPage {...props} chartDateRange={chartDateRange} selectedTabIndex={3} setSelectedTabIndex={setSelectedTabIndex}/>
              }
              {
                selectedTabIndex === 4 &&
                <HelpdeskPage {...props} chartDateRange={chartDateRange}/>
              }
              
            </div>
          </Page>
        </Tabs>
      </Fragment>
    );
  }
}

export default withFirebase(Index);